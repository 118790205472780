import React from 'react';

import SEO from '../components/content/seo';
import Layout from '../containers/layout';
import Privacy from '../components/pages/privacy';

const seo = {
  title: 'Privacy Policy',
  description: 'Privacy Policy for www.livemenu.io.',
  keywords: ['privacy', 'policy'],
};

const PrivacyPage = props => {
  return (
    <Layout>
      <SEO
        title={seo.title}
        description={seo.description}
        keywords={seo.keywords}
      />
      <Privacy />
    </Layout>
  );
};

export default PrivacyPage;
