import React from 'react';
import { Link as SiteLink } from 'gatsby';
import { Row, Col } from 'antd';
import Obfuscate from 'react-obfuscate';

import { Page, Section, styles } from '../layout/page';

const CONTACT_EMAIL = 'info@livemenu.io';

const Privacy = () => (
  <Page>
    <Section color='white'>
      <Row gutter={32} type='flex' justify='center'>
        <Col xs={24} sm={24} md={24} lg={18} xl={18}>
          <article className={styles.policy}>
            <h1>Privacy Policy</h1>
            <div className={styles.date}>Last Modified: August 30, 2019</div>

            <section id='introduction'>
              <h2>Introduction</h2>
              <p>
                LiveMenu LLC ("Company" or "We") respect your privacy and are
                committed to protecting it through our compliance with this
                policy.
              </p>
              <p>
                This policy describes the types of information we may collect
                from you or that you may provide when you visit the website
                www.livemenu.io (our "Website") and our practices for
                collecting, using, maintaining, protecting, and disclosing that
                information.
              </p>
              <p>This policy applies to information we collect:</p>
              <ul>
                <li>On this Website.</li>
                <li>
                  In email, text, and other electronic messages between you and
                  this Website.
                </li>
              </ul>
              <p>It does not apply to information collected by:</p>
              <ul>
                <li>
                  Us offline or through any other means, including on any other
                  website operated by Company or any third party (including our
                  affiliates and subsidiaries); or
                </li>
                <li>
                  Any third party (including our affiliates and subsidiaries),
                  including through any application or content (including
                  advertising) that may link to or be accessible from or on the
                  Website.
                </li>
              </ul>
              <p>
                Please read this policy carefully to understand our policies and
                practices regarding your information and how we will treat it.
                If you do not agree with our policies and practices, your choice
                is not to use our Website. By accessing or using this Website,
                you agree to this privacy policy. This policy may change from
                time to time (see Changes to Our Privacy Policy). Your continued
                use of this Website after we make changes is deemed to be
                acceptance of those changes, so please check the policy
                periodically for updates.
              </p>
            </section>

            <section id='children'>
              <h2>Children Under the Age of 16</h2>
              <p>
                Our Website is not intended for children under 16 years of age.
                No one under age 16 may provide any information to or on the
                Website. We do not knowingly collect personal information from
                children under 16. If you are under 16, do not use or provide
                any information on this Website or on or through any of its
                features. If we learn we have collected or received personal
                information from a child under 16 without verification of
                parental consent, we will delete that information. If you
                believe we might have any information from or about a child
                under 16, please contact us at{' '}
                <Obfuscate
                  email={CONTACT_EMAIL}
                  headers={{
                    subject: 'Child Information',
                  }}
                />
              </p>
              <p>
                California residents under 16 years of age may have additional
                rights regarding the collection and sale of their personal
                information. Please see Your California Privacy Rights for more
                information.
              </p>
            </section>

            <section id='information-collected'>
              <h2>Information We Collect About You and How We Collect It</h2>
              <p>
                We collect several types of information from and about users of
                our Website, including information:
              </p>
              <ul>
                <li>
                  By which you may be personally identified, such as name,
                  postal address, e-mail address, telephone number, or any other
                  identifier by which you may be contacted online or offline ("
                  <strong>personal information</strong>");
                </li>
                <li>
                  That is about you but individually does not identify you;
                  and/or
                </li>
                <li>
                  About your internet connection, the equipment you use to
                  access our Website, and usage details.
                </li>
              </ul>
              <p>We collect this information:</p>
              <ul>
                <li>Directly from you when you provide it to us.</li>
                <li>
                  Automatically as you navigate through the site. Information
                  collected automatically may include usage details, IP
                  addresses, and information collected through cookies and web
                  beacons.
                </li>
                <li>From third parties, for example, our business partners.</li>
              </ul>
            </section>

            <section id='information-provided'>
              <h2>Information You Provide to Us</h2>
              <p>
                The information we collect on or through our Website may
                include:
              </p>
              <ul>
                <li>
                  Information that you provide by filling in forms on our
                  Website. This includes information provided at the time of
                  registering to use our Website, subscribing to our service,
                  posting material, or requesting further services. We may also
                  ask you for information when you enter a contest or promotion
                  sponsored by us, and when you report a problem with our
                  Website.
                </li>
                <li>
                  Records and copies of your correspondence (including email
                  addresses), if you contact us.
                </li>
                <li>
                  Your responses to surveys that we might ask you to complete
                  for research purposes.
                </li>
                <li>
                  Details of transactions you carry out through our Website and
                  of the fulfillment of your orders. You may be required to
                  provide financial information before placing an order through
                  our Website.
                </li>
                <li>Your search queries on the Website.</li>
              </ul>
              <p>
                You also may provide information to be published or displayed
                (hereinafter, "<strong>posted</strong>") on public areas of the
                Website, or transmitted to other users of the Website or third
                parties (collectively, "<strong>User Contributions</strong>").
                Your User Contributions are posted on and transmitted to others
                at your own risk. Although you may set certain privacy settings
                for such information by logging into your account profile,
                please be aware that no security measures are perfect or
                impenetrable. Additionally, we cannot control the actions of
                other users of the Website with whom you may choose to share
                your User Contributions. Therefore, we cannot and do not
                guarantee that your User Contributions will not be viewed by
                unauthorized persons.
              </p>
            </section>

            <section id='automatic-data-collection'>
              <h2>
                Information We Collect Through Automatic Data Collection
                Technologies
              </h2>
              <p>
                As you navigate through and interact with our Website, we may
                use automatic data collection technologies to collect certain
                information about your equipment, browsing actions, and
                patterns, including:
              </p>
              <ul>
                <li>
                  Details of your visits to our Website, including traffic data,
                  location data, logs, and other communication data and the
                  resources that you access and use on the Website.
                </li>
                <li>
                  Information about your computer and internet connection,
                  including your IP address, operating system, and browser type.
                </li>
              </ul>
              <p>
                The information we collect automatically may include personal
                information, or we may maintain it or associate it with personal
                information we collect in other ways or receive from third
                parties. It helps us to improve our Website and to deliver a
                better and more personalized service, including by enabling us
                to:
              </p>
              <ul>
                <li>Estimate our audience size and usage patterns.</li>
                <li>
                  Store information about your preferences, allowing us to
                  customize our Website according to your individual interests.
                </li>
                <li>Speed up your searches.</li>
                <li>Recognize you when you return to our Website.</li>
              </ul>
              <p>
                The technologies we use for this automatic data collection may
                include:
              </p>
              <ul>
                <li>
                  <strong>Cookies (or browser cookies).</strong> A cookie is a
                  small file placed on the hard drive of your computer. You may
                  refuse to accept browser cookies by activating the appropriate
                  setting on your browser. However, if you select this setting
                  you may be unable to access certain parts of our Website.
                  Unless you have adjusted your browser setting so that it will
                  refuse cookies, our system will issue cookies when you direct
                  your browser to our Website.
                </li>
                <li>
                  <strong>Web Beacons.</strong> Pages of our Website and our
                  emails may contain small electronic files known as web beacons
                  (also referred to as clear gifs, pixel tags, and single-pixel
                  gifs) that permit the Company, for example, to count users who
                  have visited those pages or opened an email and for other
                  related website statistics (for example, recording the
                  popularity of certain website content and verifying system and
                  server integrity).
                </li>
              </ul>
            </section>

            <section id='information-use'>
              <h2>How We Use Your Information</h2>
              <p>
                We use information that we collect about you or that you provide
                to us, including any personal information:
              </p>
              <ul>
                <li>To present our Website and its contents to you.</li>
                <li>
                  To provide you with information, products, or services that
                  you request from us.
                </li>
                <li>To fulfill any other purpose for which you provide it.</li>
                <li>
                  To provide you with notices about your account, including
                  expiration and renewal notices.
                </li>
                <li>
                  To carry out our obligations and enforce our rights arising
                  from any contracts entered into between you and us, including
                  for billing and collection.
                </li>
                <li>
                  To notify you about changes to our Website or any products or
                  services we offer or provide though it.
                </li>
                <li>
                  To allow you to participate in interactive features on our
                  Website.
                </li>
                <li>
                  In any other way we may describe when you provide the
                  information.
                </li>
                <li>For any other purpose with your consent.</li>
              </ul>
              <p>
                We may also use your information to contact you about goods and
                services that may be of interest to you. If you do not want us
                to use your information in this way, please check the relevant
                box located on the form on which we collect your data (the
                registration form). For more information, see Choices About How
                We Use and Disclose Your Information.
              </p>
              <p>
                We may use the information we have collected from you to enable
                us to display advertisements to our advertisers' target
                audiences. Even though we do not disclose your personal
                information for these purposes without your consent, if you
                click on or otherwise interact with an advertisement, the
                advertiser may assume that you meet its target criteria.
              </p>
            </section>

            <section id='information-disclosure'>
              <h2>Disclosure of Your Information</h2>
              <p>
                We may disclose aggregated information about our users, and
                information that does not identify any individual, without
                restriction.
              </p>
              <p>
                We may disclose personal information that we collect or you
                provide as described in this privacy policy:
              </p>
              <ul>
                <li>To our subsidiaries and affiliates.</li>
                <li>
                  To contractors, service providers, and other third parties we
                  use to support our business and who are bound by contractual
                  obligations to keep personal information confidential and use
                  it only for the purposes for which we disclose it to them.
                </li>
                <li>
                  To a buyer or other successor in the event of a merger,
                  divestiture, restructuring, reorganization, dissolution, or
                  other sale or transfer of some or all of LiveMenu LLC's
                  assets, whether as a going concern or as part of bankruptcy,
                  liquidation, or similar proceeding, in which personal
                  information held by LiveMenu LLC about our Website users is
                  among the assets transferred
                </li>
                <li>
                  To third parties to market their products or services to you
                  if you have consented to these disclosures. We contractually
                  require these third parties to keep personal information
                  confidential and use it only for the purposes for which we
                  disclose it to them. For more information, see Choices About
                  How We Use and Disclose Your Information.
                </li>
                <li>
                  To fulfill the purpose for which you provide it. For example,
                  if you give us an email address to use the "email a friend"
                  feature of our Website, we will transmit the contents of that
                  email and your email address to the recipients.
                </li>
                <li>
                  For any other purpose disclosed by us when you provide the
                  information.
                </li>
                <li>With your consent.</li>
              </ul>
              <p>We may also disclose your personal information:</p>
              <ul>
                <li>
                  To comply with any court order, law, or legal process,
                  including to respond to any government or regulatory request.
                </li>
                <li>
                  To enforce or apply our terms of use or terms of sale and
                  other agreements, including for billing and collection
                  purposes.
                </li>
                <li>
                  If we believe disclosure is necessary or appropriate to
                  protect the rights, property, or safety of LiveMenu LLC, our
                  customers, or others. This includes exchanging information
                  with other companies and organizations for the purposes of
                  fraud protection and credit risk reduction.
                </li>
              </ul>
            </section>

            <section id='use-and-disclosure-choices'>
              <h2>Choices About How We Use and Disclose Your Information</h2>
              <p>
                We strive to provide you with choices regarding the personal
                information you provide to us. We have created mechanisms to
                provide you with the following control over your information:{' '}
              </p>
              <ul>
                <li>
                  <strong>Tracking Technologies and Advertising.</strong> You
                  can set your browser to refuse all or some browser cookies, or
                  to alert you when cookies are being sent. If you disable or
                  refuse cookies, please note that some parts of this site may
                  then be inaccessible or not function properly.
                </li>
                <li>
                  <strong>
                    Disclosure of Your Information for Third-Party Advertising.
                  </strong>{' '}
                  If you do not want us to share your personal information with
                  unaffiliated or non-agent third parties for promotional
                  purposes, you can opt out by checking the relevant box located
                  on the form on which we collect your data (the registration
                  form). You can also always opt out by logging into the Website
                  and adjusting your user preferences in your account profile,
                  by checking or unchecking the relevant boxes or by sending us
                  an email stating your request to{' '}
                  <Obfuscate
                    email={CONTACT_EMAIL}
                    headers={{
                      subject: 'Information Disclosure - Opt Out',
                    }}
                  />
                  .
                </li>
                <li>
                  <strong>Promotional Offers from the Company.</strong> If you
                  do not wish to have your contact information used by the
                  Company to promote our own or third parties' products or
                  services, you can opt out by checking the relevant box located
                  on the form on which we collect your data (the
                  order/registration form) or at any other time by logging into
                  the Website and adjusting your user preferences in your
                  account profile by checking or unchecking the relevant boxes
                  or by sending us an email stating your request to
                  karise@livemenu.io. If we have sent you a promotional email,
                  you may send us a return email asking to be omitted from
                  future email distributions. This opt out does not apply to
                  information provided to the Company as a result of a product
                  purchase, warranty registration, product service experience,
                  or other transactions.
                </li>
                <li>
                  <strong>Targeted Advertising.</strong> If you do not want us
                  to use information that we collect or that you provide to us
                  to deliver advertisements according to our advertisers'
                  target-audience preferences, you can opt out by checking the
                  relevant box located on the form on which we collect your data
                  (the order/registration form) or at any other time by logging
                  into the Website and adjusting your user preferences in your
                  account profile by checking or unchecking the relevant boxes
                  or by sending us an email stating your request to{' '}
                  <Obfuscate
                    email={CONTACT_EMAIL}
                    headers={{
                      subject: 'Targeted Advertising - Opt Out',
                    }}
                  />
                </li>
              </ul>
              <p>
                We do not control third parties' collection or use of your
                information to serve interest-based advertising. However these
                third parties may provide you with ways to choose not to have
                your information collected or used in this way. You can opt out
                of receiving targeted ads from members of the Network
                Advertising Initiative ("<strong>NAI</strong>") on the NAI's
                website.
              </p>
            </section>

            <section id='accessing-information'>
              <h2>Accessing and Correcting Your Information</h2>
              <p>
                You can review and change your personal information by logging
                into the Website and visiting your account profile page.
              </p>
              <p>
                If you delete your User Contributions from the Website, copies
                of your User Contributions may remain viewable in cached and
                archived pages, or might have been copied or stored by other
                Website users. Proper access and use of information provided on
                the Website, including User Contributions, is governed by our{' '}
                <SiteLink to='/terms'>Terms of Use</SiteLink>.
              </p>
            </section>

            <section id='data-security'>
              <h2>Data Security</h2>
              <p>
                We have implemented measures designed to secure your personal
                information from accidental loss and from unauthorized access,
                use, alteration, and disclosure. All information you provide to
                us is stored on our secure servers behind firewalls. Any payment
                transactions will be encrypted.
              </p>
              <p>
                The safety and security of your information also depends on you.
                Where we have given you (or where you have chosen) a password
                for access to certain parts of our Website, you are responsible
                for keeping this password confidential. We ask you not to share
                your password with anyone. We urge you to be careful about
                giving out information in public areas of the Website like
                message boards. The information you share in public areas may be
                viewed by any user of the Website.
              </p>
              <p>
                Unfortunately, the transmission of information via the internet
                is not completely secure. Although we do our best to protect
                your personal information, we cannot guarantee the security of
                your personal information transmitted to our Website. Any
                transmission of personal information is at your own risk. We are
                not responsible for circumvention of any privacy settings or
                security measures contained on the Website.
              </p>
            </section>

            <section id='changes-to-policy'>
              <h2>Changes to Our Privacy Policy</h2>
              <p>
                It is our policy to post any changes we make to our privacy
                policy on this page with a notice that the privacy policy has
                been updated on the Website home page. If we make material
                changes to how we treat our users' personal information, we will
                notify you through a notice on the Website home page. The date
                the privacy policy was last revised is identified at the top of
                the page. You are responsible for ensuring we have an up-to-date
                active and deliverable email address for you, and for
                periodically visiting our Website and this privacy policy to
                check for any changes.
              </p>
            </section>

            <section id='contact-information'>
              <h2>Contact Information</h2>
              <p>
                To ask questions or comment about this privacy policy and our
                privacy practices, contact us at{' '}
                <Obfuscate
                  email={CONTACT_EMAIL}
                  headers={{
                    subject: 'Questions / Comments',
                  }}
                />
                .
              </p>
              <p>
                To register a complaint or concern, please email{' '}
                <Obfuscate
                  email={CONTACT_EMAIL}
                  headers={{
                    subject: 'Concern / Complaint',
                  }}
                />
                .
              </p>
            </section>
          </article>
        </Col>
      </Row>
    </Section>
  </Page>
);

export default Privacy;
